import AddressDTO from "@/dto/AddressDTO";

export default class SaveW4RequestDTO {

    public publicId = "";
    public firstName = "";
    public lastName = "";
    public address = new AddressDTO()
    public employeeSsn = "";
    public isSingleMarriedSeparately = true;
    public isMarriedOrWidow = false;
    public isHeadOfHousehold = false;
    public is2Jobs = false;
    public pieceChildrenUnderSeventeenOn2000: number | null = null;
    public pieceOtherDependentsOn500: number | null = null;
    public otherAmount: number | null = null;
    public otherIncome: number | null = null;
    public deductions: number | null = null;
    public extraWithholding: number | null = null;
    public incomeTwoJobs: number | null = null;
    public incomeThreeJobsA: number | null = null;
    public incomeThreeJobsB: number | null = null;
    public incomeThreeJobsC: number | null = null;
    public numberOfPeriod: number | null = null;
    public divide: number | null = null;
    public estimateOfDeductions: number | null = null;
    public estimate: number | null = null;
    public differenceOfEstimates: number | null = null;
    public estimateOfStudent: number | null = null;
    public sumOfEstimate: number | null = null;

    public merge(obj: SaveW4RequestDTO) {
        if (obj.firstName) {
            this.firstName = obj.firstName;
        }
        if (obj.lastName) {
            this.lastName = obj.lastName;
        }
        if (obj.employeeSsn) {
            this.employeeSsn = obj.employeeSsn;
        }
        if (obj.address) {
            this.address = obj.address;
        }
    }

}

export class Address {

    public address = "";

    public city = "";

    public state = "";

    public zipCode: number | null = null;

}