















































































































































































































import {Component, Vue} from "vue-property-decorator";
import DocumentService from "@/services/DocumentService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import {namespace} from "vuex-class";
import {STATE_LIST} from "@/constants/ProfileConstants";
import SaveW4RequestDTO from "@/dto/archive/SaveW4RequestDTO";
import RegexUtils from "@/utils/RegexUtils";
import AutoComplete from "@/components/common/AutoComplete.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import {ifValid, validate} from "@/utils/ComponentUtils";
import SaveW9RequestDTO from "@/dto/archive/SaveW9RequestDTO";
import AddressDTO from "@/dto/AddressDTO";
import BankAccountDTO from "@/dto/BankAccountDTO";
import {HolderType} from "@/dto/HolderType";
import {BankAccountType} from "@/dto/BankAccountType";

const AppModule = namespace("App");


@Component({
	computed: {
		AutoCompleteDTO() {
			return AutoCompleteDTO
		}
	},
	components: {PortalInput, AutoComplete}
})
export default class FormW4 extends Vue {

	private successful = false;

	private message = "";

	private states = STATE_LIST

	@AppModule.State
	private loading!: boolean;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private exists = true;

	private processed = false;

	private request = new SaveW4RequestDTO();


	public created(): void {
		this.request.publicId = this.$route.params.publicId;
		this.loadForm();
	}

	private loadForm(): void{
		this.startLoading();
		DocumentService.loadPreparedPublicForm<SaveW4RequestDTO>(this.$route.params.publicId).then(
			success => {
				this.request.merge(success.data);
				this.stopLoading()
			},
			error => {
				this.exists = false;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string
				this.successful = false;
				this.stopLoading()
			});
	}

	private save(): void {
		ifValid(this, () => {
			this.message = "";
			this.startLoading();
			DocumentService.saveW4(this.request).then(
				success => {
					this.successful = true;
					this.processed = true;
				},
				error => {
					this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
				}
			).then(() => this.stopLoading());
		})
	}

	private onSingle(): void {
		this.request.isSingleMarriedSeparately = true;
		this.request.isMarriedOrWidow = false;
		this.request.isHeadOfHousehold = false;
	}

	private onMarried(): void {
		this.request.isSingleMarriedSeparately = false;
		this.request.isMarriedOrWidow = true;
		this.request.isHeadOfHousehold = false;
	}

	private onHoh(): void {
		this.request.isSingleMarriedSeparately = false;
		this.request.isMarriedOrWidow = false;
		this.request.isHeadOfHousehold = true;
	}

}
